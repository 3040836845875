<template>
  <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/>

  <button v-if="setnotifyme" @click="notifyMe()">Notify me!</button>
</template>

<script >
import HelloWorld from './components/HelloWorld.vue'
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBZXOdtYpkX4eh5GBUW8uu0p4MqmklgFXE",
  authDomain: "hello-notification-2db39.firebaseapp.com",
  projectId: "hello-notification-2db39",
  storageBucket: "hello-notification-2db39.appspot.com",
  messagingSenderId: "187164567341",
  appId: "1:187164567341:web:288e2201706b683e0f55a4",
  measurementId: "G-L5HTRYKJ8E"
};

initializeApp(firebaseConfig);


// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging();
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
});

getToken(messaging, { vapidKey: 'BMT_sbuE9dD1qi334hY9O5fWGLOcGFCpz6ti9EyrIqQECH8zLjhEvdVX2HtP_4eze5JBcGrKsZs4sxE8tk4c-lo' }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    console.log("Token is:",currentToken);
    // ...
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});

export default {
  components: {
    HelloWorld,
  },
  data() {
    return {
      setnotifyme: false,
    };
  },
  methods:{
    notifyMe: function () {
      if (!("Notification" in window)) {
        // Check if the browser supports notifications
        alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        // Check whether notification permissions have already been granted;
        // if so, create a notification
        alert('granted');
        // …
      } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
        this.setnotifyme = true;
        Notification.requestPermission().then((permission) => {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            console.log('not granted');
            // …
          }
        });
      }

      // At last, if the user has denied notifications, and you
      // want to be respectful there is no need to bother them anymore.
    }
  }
}



</script>


<style>
body {
  background: #6d2faf;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  color: white;
  margin-top: 60px;
}
</style>
